<template>
  <div class="compre-info" v-if="showCard">
    <!-- <van-nav-bar title="综合资讯" left-arrow @click-left="onClickLeft" /> -->
    <van-tabs v-model="active" swipeable @click="changeTitle">
      <van-tab v-for="item in tabList" :title="item.dictValue" :key="item.id" :value="item.dictKey"></van-tab>
    </van-tabs>
    <div class="compre-info-body">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="30"
        >
          <div class="list" v-for="(item,index) in list" :key="index" @click="goDetail(item.id)">
            <div class="img">
              <img :src="item.titleImg" alt />
            </div>
            <div class="info">
              <h4>{{item.title}}</h4>
              <div class="time">{{item.publishTime}}</div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import { getDict, getKindList } from '@/api/compreInfo';
export default {
  data() {
    return {
      active: 0,
      currentPage: 1,
      list: [],
      typeKey: 0,
      loading: false,
      finished: false,
      refreshing: false,
      tabList: [
        {
          id: 1,
          type: 'all',
          title: '全部'
        },
        {
          id: 2,
          type: 'culture',
          title: '文化'
        },
        {
          id: 3,
          type: 'specialty',
          title: '专业'
        },
        {
          id: 4,
          type: 'law',
          title: '法律'
        },
        {
          id: 5,
          type: 'policy',
          title: '政策'
        }
      ],
      showCard: false
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push({ name: 'mainPage' });
    },
    //请求列表
    getPage() {
      getKindList({
        current: this.currentPage + 1,
        size: 10,
        typeKey: this.typeKey
      })
        .then(res => {
          if (res.success) {
            //加载完成
            this.currentPage++;
            this.refreshing = false;
            if (res.data.records.length === 0) {
              this.finished = true;
              return false;
            }
            this.loading = false;
            //用concat去拼接
            let arr = this.list.concat(res.data.records);
            // let arr = [...this.List, ...res.data.records];
            this.list = arr;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //获取第一页数据
    getFirstPage(current) {
      getKindList({
        current,
        size: 10,
        typeKey: this.typeKey
      })
        .then(res => {
          if (res.success) {
            this.refreshing = false;
            this.list = res.data.records;
            if (res.data.records.length !== 10) {
              this.finished = true;
            }
            // this.currentPage++;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    onLoad() {
      setTimeout(() => {
        this.getPage();
        this.loading = false;
      }, 500);
    },
    //刷新数据
    onRefresh() {
      // 清空列表数据
      this.list = [];
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getFirstPage(1);
    },
    //切换tabs
    changeTitle() {
      this.typeKey = this.tabList[this.active].dictKey;
      this.currentPage = 1;
      this.list = [];
      this.finished = false;
      this.getFirstPage(1);
    },
    //去详情
    goDetail(id) {
      this.$router.push({ name: 'compreInfoDetail', query: { id: id } });
    }
  },
  created() {
    // this.typeKey = this.tabList[this.active].dictKey;
    //请求字段Title
    this.typeKey = this.$route.query.typeKey; //首页传来
    // this.active=this.typeKey//列表Tab选中
    getDict({
      code: 'ARTICLE_TYPE'
    }).then(res => {
      // console.log(res);
      if (res.success) {
        const tabList = res.data;
        this.tabList = res.data;
        this.showCard = true;

        if (this.typeKey) {
          let active = 0;
          for (let i = 0; i < tabList.length; i++) {
            if (tabList[i].dictValue === '党建风采') {
              active = i;
            }
          }
          this.active = active;
          this.getFirstPage(1);
          return false;
        }
        this.getFirstPage(1);
      }
    });
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/75;
/deep/ .van-tab {
  width: 86 * $px !important;
}
/deep/ .van-tab__text {
  white-space: nowrap !important;
}
/deep/ .van-tabs__wrap--scrollable .van-tab {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 26%;
  flex: 0 0 26%;
  flex-basis: 26% !important;
}
.compre-info {
  display: flex;
  flex-direction: column;
  height: 100%;
  height: 100%;
  .tab-title {
    height: 88 * $px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    li {
      font-size: 28 * $px;
      height: 100%;
      line-height: 88 * $px;
      span {
        display: inline-block;
        height: 100%;
        box-sizing: border-box;
        &.clickStyle {
          border-bottom: 2 * $px solid #ff192f;
          color: #ff192f;
        }
      }
    }
  }
  .compre-info-body {
    flex: 1;
    overflow: scroll;
    background: #f3f3f3;
    padding: 20 * $px;
    box-sizing: border-box;
    .list {
      display: flex;
      margin-bottom: 20 * $px;
      padding: 20 * $px;
      background: #fff;
      border-radius: 8px;
      .img {
        width: 120 * $px;
        height: 120 * $px;
        background: rgba(238, 238, 238, 1);
        border-radius: 8px;
        margin-right: 20 * $px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .info {
        flex: 1;
        font-size: 28 * $px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h4 {
          width: fit-content;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
.nameTab {
  flex-basis: 27% !important;
}
.height100 {
  width: 100%;
  height: 200 * $px;
}
</style>