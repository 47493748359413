import request from '../request'


// export const getAllList = (params) => {
//     return request({
//         url: `/blade-party-admin/dict-biz/list`,
//         params,
//         headers: {
//             'Blade-Auth': 'bearer eyJ0eXAiOiJKc29uV2ViVG9rZW4iLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJpc3N1c2VyIiwiYXVkIjoiYXVkaWVuY2UiLCJ0ZW5hbnRfaWQiOiI5Mzg5NDIiLCJyb2xlX25hbWUiOiIiLCJ1c2VyX2lkIjoiMTI0ODE3MzAzMjUyMjkxNTg0MiIsInVzZXJfbmFtZSI6IjE3NzMwMjcyOTYyIiwibmlja19uYW1lIjoi6ZmI6YeR5a6dIiwidG9rZW5fdHlwZSI6ImFjY2Vzc190b2tlbiIsImRlcHRfaWQiOiIxMjQxNjI1OTU4ODY0MTYyODE4IiwiYWNjb3VudCI6IjE3NzMwMjcyOTYyIiwiY2xpZW50X2lkIjoic3dvcmQiLCJleHAiOjE1OTQ2MzQwNzgsIm5iZiI6MTU4NzQzNDA3OH0.5awy-liXOBX3HQqH_IvCrJ61TNp1K-sMKKDjAuUETdo'
//         }
//     })
// };
//获取字段
export const getDict = () => {
    return request({
        url: `/blade-party-admin/min/cms/article-infos/types`,
        params: {},
        headers: {
            'User-Type': 'DINGTALK',
            'Blade-Auth': 'bearer eyJ0eXAiOiJKc29uV2ViVG9rZW4iLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJpc3N1c2VyIiwiYXVkIjoiYXVkaWVuY2UiLCJ0ZW5hbnRfaWQiOiI5Mzg5NDIiLCJyb2xlX25hbWUiOiIiLCJ1c2VyX2lkIjoiMTI0ODE3MzAzMjUyMjkxNTg0MiIsInVzZXJfbmFtZSI6IjE3NzMwMjcyOTYyIiwibmlja19uYW1lIjoi6ZmI6YeR5a6dIiwidG9rZW5fdHlwZSI6ImFjY2Vzc190b2tlbiIsImRlcHRfaWQiOiIxMjQxNjI1OTU4ODY0MTYyODE4IiwiYWNjb3VudCI6IjE3NzMwMjcyOTYyIiwiY2xpZW50X2lkIjoic3dvcmQiLCJleHAiOjE1OTQ2MzQwNzgsIm5iZiI6MTU4NzQzNDA3OH0.5awy-liXOBX3HQqH_IvCrJ61TNp1K-sMKKDjAuUETdo'
        }
    })
};

//获取列表
export const getKindList = (params) => {
    return request({
        url: `/blade-party-admin/min/cms/article-infos/page`,
        params,
        headers: {
            'User-Type': 'DINGTALK',
            'Blade-Auth': 'bearer eyJ0eXAiOiJKc29uV2ViVG9rZW4iLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJpc3N1c2VyIiwiYXVkIjoiYXVkaWVuY2UiLCJ0ZW5hbnRfaWQiOiI5Mzg5NDIiLCJyb2xlX25hbWUiOiIiLCJ1c2VyX2lkIjoiMTI0ODE3MzAzMjUyMjkxNTg0MiIsInVzZXJfbmFtZSI6IjE3NzMwMjcyOTYyIiwibmlja19uYW1lIjoi6ZmI6YeR5a6dIiwidG9rZW5fdHlwZSI6ImFjY2Vzc190b2tlbiIsImRlcHRfaWQiOiIxMjQxNjI1OTU4ODY0MTYyODE4IiwiYWNjb3VudCI6IjE3NzMwMjcyOTYyIiwiY2xpZW50X2lkIjoic3dvcmQiLCJleHAiOjE1OTQ2MzQwNzgsIm5iZiI6MTU4NzQzNDA3OH0.5awy-liXOBX3HQqH_IvCrJ61TNp1K-sMKKDjAuUETdo'
        }
    })
};

//进入详情
export const toDetail = (params) => {
    return request({
        url: `/blade-party-admin/min/cms/article-infos/detail`,
        params,
        headers: {
            'User-Type': 'DINGTALK',
            'Blade-Auth': 'bearer eyJ0eXAiOiJKc29uV2ViVG9rZW4iLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJpc3N1c2VyIiwiYXVkIjoiYXVkaWVuY2UiLCJ0ZW5hbnRfaWQiOiI5Mzg5NDIiLCJyb2xlX25hbWUiOiIiLCJ1c2VyX2lkIjoiMTI0ODE3MzAzMjUyMjkxNTg0MiIsInVzZXJfbmFtZSI6IjE3NzMwMjcyOTYyIiwibmlja19uYW1lIjoi6ZmI6YeR5a6dIiwidG9rZW5fdHlwZSI6ImFjY2Vzc190b2tlbiIsImRlcHRfaWQiOiIxMjQxNjI1OTU4ODY0MTYyODE4IiwiYWNjb3VudCI6IjE3NzMwMjcyOTYyIiwiY2xpZW50X2lkIjoic3dvcmQiLCJleHAiOjE1OTQ2MzQwNzgsIm5iZiI6MTU4NzQzNDA3OH0.5awy-liXOBX3HQqH_IvCrJ61TNp1K-sMKKDjAuUETdo'
        }
    })
}

// 获取资讯详情
export const articleInfosDetail = (params) => {
    return request({
        url: `/blade-party-admin/min/cms/article-infos/detail`,
        params: params,
        method: 'GET',
    })
};